var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pb-2 d-flex-center",
    staticStyle: {
      "min-width": "1080px"
    }
  }, [_vm.stateLoading || !_vm.bookingData || _vm.stateLoadingCategory ? _c('div', {
    staticClass: "text-center py-2 mt-2 mt-lg-4"
  }, [_c('p', {
    staticClass: "font-weight-bolder text-airline"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.loading2')) + " ")]), _c('div', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "info"
    }
  })], 1)]) : [_vm.bookingData && !_vm.isEmpty(_vm.listTrips) ? _c('div', {
    staticStyle: {
      "max-width": "1200px"
    }
  }, [!['CANCEL'].includes(_vm.bookingData.status) ? _c('TrainTicketConfig', {
    attrs: {
      "config": _vm.config
    }
  }) : _vm._e(), _c('TrainTicketAlertWarning'), _c('vue-html2pdf', {
    ref: "html2Pdf",
    staticStyle: {
      "min-width": "1080px"
    },
    attrs: {
      "show-layout": false,
      "float-layout": false,
      "enable-download": true,
      "preview-modal": !_vm.isMobileView,
      "paginate-elements-by-height": 1400,
      "manual-pagination": false,
      "html-to-pdf-options": _vm.pdfOptions,
      "pdf-content-width": "100%"
    }
  }, [_c('section', {
    attrs: {
      "slot": "pdf-content"
    },
    slot: "pdf-content"
  }, [_c('ElectronicTicketTrain', {
    attrs: {
      "from-ticket-data": _vm.bookingData,
      "config": _vm.config,
      "listTrips": _vm.listTrips,
      "passengerTargetList": _vm.getListPassengerTarget
    },
    on: {
      "update:config": function updateConfig($event) {
        _vm.config = $event;
      },
      "update:pricingInfo": function updatePricingInfo($event) {
        return _vm.pricingInfo = $event;
      }
    }
  })], 1)]), _c('div', {
    staticClass: "d-flex-center gap-3 py-1"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(234, 84, 85, 0.15)',
      expression: "'rgba(234, 84, 85, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-danger",
      "pill": ""
    },
    on: {
      "click": _vm.handleCloseTab
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('close')) + " tab ")]), !['CANCEL'].includes(_vm.bookingData.status) ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "btn-gradient width-150 mt-lg-25",
    attrs: {
      "pill": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openModalRename('img');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.exportIMG')) + " ")]) : _vm._e(), !['CANCEL'].includes(_vm.bookingData.status) ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "btn-gradient width-150 mt-lg-25",
    attrs: {
      "pill": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openModalRename('pdf');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.exportPDF')) + " ")]) : _vm._e()], 1)], 1) : _vm._e()], _c('b-modal', {
    attrs: {
      "id": "modal-rename-file",
      "header-bg-variant": "light-info",
      "centered": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "text-heading-3 m-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.renameExport')) + " ")])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "outline-danger",
            "pill": ""
          },
          on: {
            "click": _vm.cancelHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('cancel')) + " ")]), _c('b-button', {
          attrs: {
            "variant": "gradient",
            "pill": "",
            "disabled": !_vm.fileNameToCustom
          },
          on: {
            "click": function click($event) {
              return _vm.exportHandle(_vm.typeToExport);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("flight.export".concat(_vm.typeToExport.toUpperCase()))) + " ")])];
      },
      proxy: true
    }])
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loadingToExport
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "filename"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-heading-5"
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.filename')) + " "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("(*)")])])];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    attrs: {
      "id": "filename",
      "placeholder": _vm.$t('flight.filenamePlaceholder'),
      "trim": ""
    },
    model: {
      value: _vm.fileNameToCustom,
      callback: function callback($$v) {
        _vm.fileNameToCustom = $$v;
      },
      expression: "fileNameToCustom"
    }
  })], 1)], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }